import { useReactiveVar } from "@apollo/client";
import { logout } from "../utils";
import { userVar } from "../utils/reactive-variable";
import { usePathname, useRouter } from "next/navigation";

export type RegisterType = "login" | "signup";

export type User = {
  token?: string | null;
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
};

interface AuthHook {
  isLoggedIn: boolean;
  user?: User;
  logout: () => void;
  updateUser: (newUserData: User) => void;
}

const useAuth = (): AuthHook => {
  const user = useReactiveVar<User | undefined>(userVar);
  const router = useRouter();
  const pathname = usePathname();

  const updateUser = (newUserData: User) => {
    const updatedUser: User = {
      ...user,
      ...newUserData,
    };
    userVar(updatedUser);
  };

  const handleLogout = () => {
    logout();
    if (pathname.includes("/me/")) {
      router.replace("/");
    }
  };

  return {
    isLoggedIn: !!user?.id,
    user,
    logout: handleLogout,
    updateUser,
  };
};

export { useAuth };
