import { parseCookies, setCookie } from "nookies";
import { v4 as uuidv4 } from "uuid";

export const sessionIdCheckAndGenerate = () => {
  const { sessionId } = parseCookies();
  if (!sessionId) {
    const sessionUuid = `Session_${uuidv4()}`;
    setCookie(null, "sessionId", sessionUuid!, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
    return sessionUuid;
  }
  return undefined;
};
