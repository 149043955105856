/* eslint-disable no-restricted-imports */
import { createSharedPathnamesNavigation } from "next-intl/navigation";
import { LinkProps as NextLinkProps } from "next/link";
import { SupportedLanguages, TLanguage } from "./i18n-settings";
import { HTMLAttributeAnchorTarget } from "react";
import { useLocale } from "./i18n-client";

const {
  Link: NextLink,
  redirect,
  usePathname,
  useRouter,
  permanentRedirect,
} = createSharedPathnamesNavigation({
  localePrefix: { mode: "always" },
  locales: SupportedLanguages,
});

interface LinkProps extends NextLinkProps {
  children?: React.ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  locale?: TLanguage;
  className?: string;
  title?: string;
  target?: HTMLAttributeAnchorTarget;
}

const Link = (props: LinkProps) => {
  const { fullHeight, fullWidth, disabled, ...restProps } = props;

  return (
    <NextLink
      {...restProps}
      style={{
        width: fullWidth ? "100%" : undefined,
        height: fullHeight ? "100%" : undefined,
        pointerEvents: disabled ? "none" : undefined,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      prefetch={false}
    />
  );
};

/** Return the current pathname without the prefixed locale */
function usePurePathname() {
  const pathname = usePathname();
  const { code } = useLocale();
  if (pathname === `/${code}`) return "/";
  return pathname.replace(`/${code}/`, "/");
}

export {
  Link,
  redirect,
  usePathname,
  usePurePathname,
  useRouter,
  permanentRedirect,
};

export {
  notFound,
  useParams,
  useSearchParams,
  RedirectType,
} from "next/navigation";
