import { IntegrationScriptControls } from "../../generated/graphql";

export enum AnalyticsEnum {
  Google_Analytics = "Google_Analytics",
  Google_Tag_Manager = "Google_Tag_Manager",
  Facebook_Pixel = "Facebook_Pixel",
}

export type AccountsMapType = {
  [key: string]: AnalyticsIntegartion | StoreIntegration;
};

export type AnalyticsIntegartion = {
  type: string;
  id: string;
  name: string;
  isActive: boolean;
};

export type StoreIntegration = {
  type: string;
  name: string;
  isActive: boolean;
  scriptControls: IntegrationScriptControls;
};

export enum CustomEventName {
  Add_to_Cart = "Add to Cart",
  Remove_From_Cart = "Remove From Cart",
  Begin_Checkout = "Checkout - Initiate",
  Checkout_Progress = "Checkout Progress",
  Checkout_Information = "Checkout - Information Page",
  Checkout_Delivery = "Checkout - Delivery Page",
  Checkout_Payment = "Checkout - Payment Page",
  Purchase = "Purchase",
  Use_Coupon = "Use Coupon",
  Checkout_Confirmation = "Checkout - Receipt Page",
  Submit_Contact_Form = "Submit Contact Form",
  View_Cart = "View Cart",
  View_Product = "View Product",
  View_Collection = "View Collection",
  Sign_Up = "Sign Up",
  Login = "Login",
}

// see this link https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
export enum PixelEventName {
  Add_to_Cart = "AddToCart",
  Begin_Checkout = "InitiateCheckout",
  Purchase = "Purchase",
  Submit_Contact_Form = "Contact",
  View_Product = "ViewContent",
  View_Collection = "ViewCategory",
  Sign_Up = "CompleteRegistration",
}

// see this link https://developers.google.com/analytics/devguides/collection/ga4/events
export enum GoogleAnalyticsEventName {
  Add_to_Cart = "add_to_cart",
  Remove_From_Cart = "remove_from_cart",
  Begin_Checkout = "begin_checkout",
  Checkout_Progress = "checkout_progress",
  Purchase = "purchase",
  Submit_Contact_Form = "generate_lead",
  View_Cart = "view_cart",
  View_Product = "view_item",
  View_Collection = "view_item_list",
  Sign_Up = "sign_up",
  Login = "login",
}

// see this link https://developers.google.com/analytics/devguides/collection/ga4/events
export enum KlaviyoEventName {
  Add_to_Cart = "Added to Cart",
  Begin_Checkout = "Started Checkout",
  View_Product = "Viewed Product",
  Sign_Up = "sign_up",
  Login = "login",
}

export enum MailchimpEventName {
  Add_to_Cart = "Added to Cart",
  Begin_Checkout = "Started Checkout",
  View_Product = "Viewed Product",
}

export enum GameballEventName {
  Sign_Up = "Sign_Up",
  Login = "Login",
  Checkout_Confirmation = "Checkout - Receipt Page",
  Add_to_Cart = "add_to_cart",
  View_Product = "product_viewed",
}

export type EventObject = {
  name: keyof typeof CustomEventName;
  parameters: any;
};
