import cookie, { CookieSerializeOptions } from "cookie";
import { IncomingMessage } from "http";

export enum CookieEnum {
  LANG = "NEXT_LOCALE",
  SESSION_ID = "sessionId",
  AUTH_TOKEN = "auth-token",
  GOOGLE_ANALYTICS = "_ga",
  FACEBOOK_PIXEL = "_fbp",
  HUBSPOT = "hubspotutk",
  TICKTOCK = "_ttp",
  SNAPCHAT = "_scid",
  AMPLITUDE = "AMP_99d42ef78d", // AMP_First ten characters of the amplitude id
}

const isBrowser = typeof window !== "undefined";

export const createCookie = (
  name: string,
  value: string,
  options?: CookieSerializeOptions
) => {
  if (!isBrowser && value) return;
  document.cookie = cookie.serialize(name, value, { path: "/", ...options });
};

export const getCookie = (
  name: string,
  request: IncomingMessage | undefined = undefined
) => {
  if (!isBrowser) {
    if (!request?.headers?.cookie || !name) return undefined;
    return cookie.parse(request?.headers?.cookie)?.[name];
  } else {
    return cookie.parse(document.cookie)?.[name];
  }
};

export const eraseCookie = (name: string, options?: CookieSerializeOptions) => {
  if (!isBrowser) return;
  document.cookie = cookie.serialize(name, "", {
    path: "/",
    maxAge: 0,
    ...options,
  });
};

export const getAmplitudeCookies = (): Record<string, string> => {
  if (!isBrowser) return {};

  const allCookies = cookie.parse(document.cookie);
  return Object.entries(allCookies)
    .filter(([key]) => key.startsWith("AMP_"))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );
};

export const getFacebookCookies = () => {
  if (!isBrowser) return {};

  const allCookies = cookie.parse(document.cookie);
  const fbc = allCookies["_fbc"];
  const fbp = allCookies["_fbp"];

  if (!fbc && !fbp) return {};

  return {
    ...(fbc && { fbc }),
    ...(fbp && { fbp }),
  };
};
