import { createAction } from "@reduxjs/toolkit";
import { CartContextType, ShippingInformationProps } from "../CartContext";

export const updateCart = createAction<CartContextType, "UPDATE_CART">(
  "UPDATE_CART"
);

export const setSideCartState = createAction<boolean, "SET_SIDE_CART_STATE">(
  "SET_SIDE_CART_STATE"
);

export const setCartLading = createAction<boolean, "SET_CART_LOADING">(
  "SET_CART_LOADING"
);

export const updateCartFetching = createAction<boolean, "UPDATE_CART_FETCHING">(
  "UPDATE_CART_FETCHING"
);

export const updateRecoveryCart = createAction<
  CartContextType,
  "updateRecoveryCart"
>("updateRecoveryCart");

export const resetRecoveryCart = createAction("resetRecoveryCart");

export const setCartPaymentPopUpState = createAction<
  boolean,
  "SET_CART_Payment_POPUP_STATE"
>("SET_CART_Payment_POPUP_STATE");
export const setCartEmptyPopUpState = createAction<
  boolean,
  "SET_CART_EMPTY_POPUP_STATE"
>("SET_CART_EMPTY_POPUP_STATE");
export const setShippingInformation = createAction<
  ShippingInformationProps,
  "SET_SHIPPING_INFORMATION"
>("SET_SHIPPING_INFORMATION");
